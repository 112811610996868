<template>
  <el-dialog title="编辑填空题" :visible="isShowType" @close='closeDialog' width='480px'
    :append-to-body="true"
    :destroy-on-close="true"
    :close-on-click-modal='false'>
      <el-form class="form-wrap" :model="info" ref="instructions" :rules="rules" label-width="100px">
        <el-form-item label="标题：" prop="questionTitle">
          <el-input type="textarea" placeholder="请输入标题" v-model="info.questionTitle"
            maxlength = "100"
            :rows="4"
            show-word-limit>
          </el-input>
        </el-form-item>
        <el-form-item label="是否必答：" prop="isMustAnswer">
          <el-radio-group v-model="info.isMustAnswer" width="110px">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="form-item-btn">
          <el-button type="primary" @click="onSave">提交</el-button>
          <el-button type="primary" @click="closeDialog">取消</el-button>
      </div>
      </el-form>
  </el-dialog>
</template>

<script>
export default {
  typeName: 'MultSetting',
  props: {
    detail: {
      type: Object,
      default: () => {
      }
    },
    isShowType: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      info: JSON.parse(JSON.stringify(this.detail)),
      rules: {
        questionTitle: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
        isMustAnswer: [
          { required: true, message: '是否必答', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
  },
  watch: {
  },
  computed: {
  },
  methods: {
    onSave () {
      this.$refs.instructions.validate().then(() => {
        this.$emit('update', this.info)
        this.closeDialog()
      })
    },
    closeDialog () {
      this.$emit('update:isShowType', false)
    }
  }
}
</script>
<style scoped lang="scss">
</style>
