<template>
  <el-dialog :title="title" :visible="isShowType" @close='closeDialog' width='480px'
    :append-to-body="true"
    :destroy-on-close="true"
    :close-on-click-modal='false'>
      <el-form class="form-wrap" :model="info" ref="instructions" :rules="rules" label-width="100px">
        <el-form-item label="标题：" prop="questionTitle">
          <el-input type="textarea" placeholder="请输入标题" v-model="info.questionTitle"
            maxlength = "100"
            :rows="4"
            show-word-limit>
          </el-input>
        </el-form-item>
        <el-form-item :label="index === 0 ? ' 选项：' : ''" v-for="(item, index) in info.answers" :key="index" :prop="'answers.'+ index +'.choice'" :rules="rules.choice">
          <div class="tool">
            <template v-if="index === 0">
              <div @click="onAdd" class="add">新增</div>
            </template>
            <template v-else>
              <div @click="onDelete(index)" class="delete">删除</div>
            </template>
          </div>
          <el-input v-model="item.choice"  placeholder="请输入选项，不超过10个字" maxlength="10" show-word-limit clearable></el-input>
        </el-form-item>
        <el-form-item label="是否必答：" prop="isMustAnswer">
          <el-radio-group v-model="info.isMustAnswer" width="110px">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="form-item-btn">
          <el-button type="primary" @click="onSave">保存</el-button>
          <el-button type="primary" @click="closeDialog">取消</el-button>
      </div>
      </el-form>
  </el-dialog>
</template>

<script>
export default {
  typeName: 'RadioSetting',
  props: {
    detail: {
      type: Object,
      default: () => {
      }
    },
    title: {
      type: String,
      default: '编辑单选题'
    },
    isShowType: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      info: JSON.parse(JSON.stringify(this.detail)),
      rules: {
        questionTitle: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
        choice: [
          { required: true, message: '请输入选项', trigger: 'blur' }
        ],
        isMustAnswer: [
          { required: true, message: '是否必答', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    console.log(this.info)
  },
  watch: {
  },
  computed: {
  },
  methods: {
    onAdd () {
      this.info.answers.push({
        choice: ''
      })
    },
    onDelete (index) {
      this.info.answers.splice(index, 1)
    },
    onSave () {
      this.$refs.instructions.validate().then(() => {
        this.$emit('update', this.info)
        this.closeDialog()
      })
    },
    closeDialog () {
      this.$emit('update:isShowType', false)
    }
  }
}
</script>
<style scoped lang="scss">
::v-deep {
  .el-form-item__content{
    width: 260px;
  }
}
.choose{
  display: flex;
}
.tool{
  display: flex;
  position: absolute;
  width: 100px;
  top: 0px;
  left: 270px;
  .add{
    color:#1AD1B0;
    margin-right: 10px;
  }
  .delete{
    color:#FF402B;
  }
  div{
    cursor: pointer;
  }
}
</style>
