<template>
  <el-card>
    <div class="addModel">
      <div class="section">
        <el-button class="mb20" @click="onAdd('1')" type="primary" icon='el-icon-plus' v-show="$ButtonShow('add')">新增单选题</el-button>
        <el-button class="mb20" @click="onAdd('2')" type="primary" icon='el-icon-plus' v-show="$ButtonShow('add')">新增多选题</el-button>
        <el-button class="mb20" @click="onAdd('3')" type="primary" icon='el-icon-plus' v-show="$ButtonShow('add')">新增填空题</el-button>
      </div>

      <div class="section">
        <el-form class="global-form2" ref="form" :model="formData" :rules="rules" label-position="right">
          <div class="border">
            <div class="align">
              <el-form-item v-if="id === ''" prop="parkIdList">
                <el-select v-model="formData.parkIdList" placeholder="请选择公园" multiple clearable filterable>
                  <el-option v-for="(item,index) in projectList" :key="index" :label="item.projectName" :value="item.projectId"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-else prop="parkId">
                <el-select v-model="formData.parkId" placeholder="请选择公园" clearable filterable>
                  <el-option v-for="(item,index) in projectList" :key="index" :label="item.projectName" :value="item.projectId"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item  prop="questionnaireTitle">
                <el-input v-model="formData.questionnaireTitle" placeholder="请输入问卷标题" maxlength = "50" show-word-limit>clearable></el-input>
              </el-form-item>
            </div>
            <div class="questions">
              <div v-for="(item, index) in questions" :key="index">
                <!-- 单选题 -->
                <el-form-item :label="(index + 1) + '.' + item.questionTitle" prop="questionTitle">
                  <div class="tool" :style="{ 'left': getLeft(item.questionTitle)}">
                    <div @click="onEdit(item, index)">编辑</div>
                    <div @click="onDelete(item.questionType, index)">删除</div>
                  </div>
                  <el-radio-group v-if="item.questionType === '1'" width="110px">
                    <el-radio disabled v-for="(item1, index1) in item.answers" :key="index1" :label="item1.choice"></el-radio>
                  </el-radio-group>

                  <el-checkbox-group v-if="item.questionType === '2'" width="110px">
                    <el-checkbox disabled v-for="(item1, index1) in item.answers" :key="index1" :label="item1.choice"></el-checkbox>
                  </el-checkbox-group>

                  <!-- 填空题 -->
                  <el-input v-if="item.questionType === '3'" disabled type="textarea" placeholder="请输入内容"
                            maxlength = "100"
                            :rows="3"
                            show-word-limit>
                  </el-input>
                </el-form-item>
              </div>
            </div>
          </div>

          <el-form-item class="align">
            <el-button type="primary" @click="submit" :disabled="disabled">保存</el-button>
            <el-button @click="$router.back()">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!-- 单选 -->
    <RadioSetting v-if="isShowRadio" :isShowType.sync = 'isShowRadio' :title="title" :detail ="detail" @update="update"></RadioSetting>
    <!-- 填空 -->
    <MultiSetting v-if="isShowMulti" :isShowType.sync = 'isShowMulti' :detail ="detail" @update="update"></MultiSetting>
  </el-card>
</template>

<script>
import RadioSetting from './RadioSetting'
import MultiSetting from './MultSetting'
export default {
  name: 'QuestionsurveyAdd',
  components: {
    RadioSetting,
    MultiSetting
  },
  data () {
    return {
      questions: [],
      formData: {
        questionnaireTitle: '',
        questions: [],
        parkId: ''
      },
      isShowRadio: false,
      isShowMulti: false,
      rules: {
        questionnaireTitle: [
          { required: true, message: '请输入问卷标题', trigger: ['blur', 'change'] }
        ],
        parkIdList: [
          { required: true, message: '请输入所属公园', trigger: ['blur', 'change'] }
        ],
        parkId: [
          { required: true, message: '请输入所属公园', trigger: ['blur', 'change'] }
        ]
      },
      count: [1, 1, 1], // 单选多选的数量
      detail: {},
      title: '',
      disabled: false
    }
  },
  computed: {
    id () {
      return this.$route.params.id || ''
    },
    projectList () {
      return this.$store.state.projectList || []
    }
  },
  created () {
    this.initData()
    if (this.id) {
      this.loadData()
    }
  },
  methods: {
    getTitle (row, index) {
      const title = row.questionType === '1' ? '单选' : row.questionType === '2' ? '多选' : '填空'
      return `${index + 1}.${title}题目${index + 1}`
    },
    getLeft (title) {
      return title.length * 14 > 1200 ? title.length * 14 - 1200 + 10 + 'px' : title.length * 14 + 'px'
    },
    initData () {
      this.questions = [{
        questionTitle: '单选题目1',
        questionType: '1',
        isMustAnswer: '1',
        answers: [
          {
            choice: '是'
          },
          {
            choice: '否'
          }
        ]
      },
      {
        questionTitle: '多选题目1',
        questionType: '2',
        isMustAnswer: '1',
        answers: [
          {
            choice: '选项1'
          },
          {
            choice: '选项2'
          }
        ]
      },
      {
        questionTitle: '填空题目1',
        questionType: '3',
        isMustAnswer: '0'
      }]
    },
    onDelete (type, index) {
      this.count[Number(type) - 1]--
      this.questions.splice(index, 1)
    },
    // 1单选，2多选，3填空
    onAdd (type) {
      const title = type === '1' ? '单选' : type === '2' ? '多选' : '填空'
      this.count[Number(type) - 1]++
      const isMust = type === '3' ? '0' : '1'
      let content = ''
      const num = this.count[Number(type) - 1]
      let count = num
      if (type === '1' || type === '2') {
        if (type === '2') {
          count = this.count[0] + num
          content = {
            questionTitle: `${title}题目${num}`,
            questionType: type,
            isMustAnswer: isMust,
            answers: [
              {
                choice: title + '选项1'
              },
              {
                choice: title + '选项2'
              }
            ]
          }
        } else {
          content = {
            questionTitle: `${title}题目${num}`,
            questionType: type,
            isMustAnswer: isMust,
            answers: [
              {
                choice: '是'
              },
              {
                choice: '否'
              }
            ]
          }
        }
      } else if (type === '3') {
        count = this.count[0] + this.count[1] + this.count[2]
        content = {
          questionTitle: `${title}题目${num}`,
          questionType: type,
          isMustAnswer: isMust
        }
      }
      this.questions.splice(count - 1, 0, content)
    },
    onEdit (row, index) {
      row.index = index
      this.detail = row
      if (row.questionType === '3') {
        this.isShowMulti = true
      } else {
        if (row.questionType === '1') {
          this.title = '编辑单选题'
        } else {
          this.title = '编辑多选题'
        }
        this.isShowRadio = true
      }
    },
    loadData () {
      this.$axios.get(this.$apis.questionSurvey.selectQuestionnaireDetail, {
        id: this.id
      }).then(res => {
        this.formData.parkId = res.parkId
        this.formData.questionnaireTitle = res.questionnaireTitle
        this.formData.id = res.id
        this.questions = res.questions || []
        this.count = [0, 0, 0]
        this.questions.forEach(item => {
          this.count[Number(item.questionType) - 1]++
        })
      })
    },
    update (row) {
      this.questions[row.index] = row
      delete this.questions[row.index].index
    },
    submit () {
      this.$refs.form.validate().then(() => {
        this.disabled = true
        if (this.questions.length <= 0) {
          this.$message.error('请新增题目')
          return false
        }
        if (!this.id) {
          delete this.formData.parkId
        }
        this.formData.questions = this.questions
        const url = this.id ? this.$apis.questionSurvey.updateQuestionnaire : this.$apis.questionSurvey.insertQuestionnaire
        this.$axios.post(url, this.formData).then(() => {
          this.$router.push('/questionsurvey')
        }).finally(() => {
          this.disabled = false
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.addModel{
  display: flex;
  .section{
    margin-top: 60px;
    &:nth-of-type(1){
      display: flex;
      flex-direction: column;
      width: 200px;
      align-items: center;
      .el-button{
        min-width: 100px;
      }

      ::v-deep {
        .el-button + .el-button {
          margin-left: 0px;
        }
      }
    }
    &:nth-of-type(2){
      display: flex;
      flex:1;
      border-radius: 0px 6px;

      ::v-deep {
          .el-form-item__label{
            display: flex;
            text-align: left;
            float: none;
            margin-left: 100px;
            max-width: 1200px;
          }
        .el-form-item__content{
          margin-left: 120px !important;
        }
        .el-form,.el-form-item__error{
          width:100%;
        }
        .el-radio-group, .el-checkbox-group{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .el-radio, .el-checkbox{
            height: 28px;
            margin-top: 12px;
          }
        }
      }
      .border{
        margin: 0px 60px 0 20px;
        height: 660px;
        background: #FFFFFF;
        box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.3);
        .questions{
          padding: 40px 0;
          overflow: auto;
          height: 500px;
        }
      }
      .align{
        padding-top: 20px;
        text-align: center;
      }
      .el-form-item{
        position: relative;
      }
      .tool{
        display: flex;
        position: absolute;
        top: -32px;
        left:100px;
        width: 100px;
        >div{
          cursor: pointer;
          &:nth-of-type(1){
            color:#1AD1B0;
            margin-right: 10px;
          }
          &:nth-of-type(2){
            color:#FF402B;
          }
        }
      }
    }
  }
}
</style>
